import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  buttonExceptional: {
    padding: "0 10px",
    fontSize: "1.125rem",
    width: "100px",
    borderRadius: "4px",
    margin: "0 10px",
  },
  buttonYes: {
    color: "#059669",
    border: "1px solid #059669",
    "&:hover": {
      backgroundColor: "#ecfdf5",
    },
  },
  buttonYesActive: {
    color: "white",
    backgroundColor: "#059669",
    "&:hover": {
      backgroundColor: "#059669",
    },
  },
  buttonNo: {
    color: "#dc2626",
    border: "1px solid #dc2626",
    "&:hover": {
      backgroundColor: "#fef2f2",
    },
  },
  buttonNoActive: {
    color: "white",
    backgroundColor: "#dc2626",
    "&:hover": {
      backgroundColor: "#dc2626",
    },
  },
  headingBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 20px",
    },
  },
  heading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "35px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      lineHeight: "27px",
    },
  },
  employeContainer: {
    padding: "0px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "top",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 20px",
    },
  },
  imageBox: {
    width: "187px",
    height: "187px",
    borderRadius: "5%",
  },
  nameBox: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
  },
  serviceContainer: {
    width: "100%",
    padding: "0px 20px 20px 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 15px 15px 15px",
    },
  },
  optionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  optionBox: {
    padding: "20px",
    width: "100px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  optionText: {
    margin: 0,
    padding: 0,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
  },
  textareaColor: {
    color: "#000",
    borderColor: "#000",
  },
}));

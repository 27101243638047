const INIT_STATE = {
  alphegaActive: false,
  webRatingData: {},
  webRatingAnsweres: {
    page: "",
    action_taken: "",
    cppq_id: "",
    created_at: "",
    device_id: "",
    dropout_page: "",
    employee_id: "",
    feedback: "",
    is_standout: "",
    journey: "",
    location_id: localStorage.getItem("location_id"),
    medicine_for: "",
    other_medication: "",
    rating: "",
    skill_id: [],
    symptoms: "",
    symptoms_duration: "",
    version: "",
    customer_email: "",
    customer_name: "",
    customer_phone: "",
    api_version: "v2",
    source: "web",
    // custom_values: [],
  },
};

export const frontWebRatingDataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_WEB_RATING_DATA":
      return {
        ...state,
        webRatingData: action?.payload,
      };
    case "SET_WEB_RATING_ANSWERES":
      return {
        ...state,
        webRatingAnsweres: {
          ...state.webRatingAnsweres,
          [action.payload.key]: action.payload.value,
        },
      };

    case "RESET_WEB_RATING_ANSWERES":
      return {
        ...state,
        webRatingAnsweres: {
          ...INIT_STATE.webRatingAnsweres,
          location_id: localStorage.getItem("location_id"),
        },
      };
    case "TOGGLE_ALPHEGA_ACTIVE_STATE":
      return {
        ...state,
        alphegaActive: action.payload,
      };
    default:
      return state;
  }
};

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    bottom: 0,
    width: "100%",
    padding: "10px",
    paddingTop: "20px",
    flex: "0 0 auto",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      // flexDirection: "column",
      marginBottom: "0.6rem",
      width: "90%",
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  buttonBox: {
    backgroundColor: localStorage.getItem("app_color"),

    border: "1px solid white",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0px 20px",
    borderRadius: "6px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "40%",
      justifyContent: "center",
    },
  },
  buttonArrow: {
    color: "white",
    margin: "0px 5px",
    fontSize: "23px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 5px",
      fontSize: "20px",
    },
  },
  buttonText: {
    margin: "0px 8px",
    color: "white",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "20px",
  },
  pageNo: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "25px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  copyrightBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  copyrightText: {
    margin: 0,
    padding: 0,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    color: theme.palette.primary.main,
  },
}));

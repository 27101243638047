import React, { useState } from "react";
import { connect } from "react-redux";
import { addAnswer } from "../../../../redux/survey/actions";
import { Box, Tooltip } from "@material-ui/core";
import emoji1 from "../../../../assets/images/web_rating/emoji_1.gif";
import emoji2 from "../../../../assets/images/web_rating/emoji_2.gif";
import emoji3 from "../../../../assets/images/web_rating/emoji_3.gif";
import emoji4 from "../../../../assets/images/web_rating/emoji_4.gif";
import emoji5 from "../../../../assets/images/web_rating/emoji_5.gif";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/styles";
// Array of emoji image sources
const emojiImage = [emoji1, emoji2, emoji3, emoji4, emoji5];

const EmojiType = (props) => {
  // Destructuring the properties passed as props
  const {
    headTonext,
    question,
    answeres,
    addAnswer_,
    survey_theme,
    onSend,
    surveyType,
    allQuestionsOriginal,
    setAllQuestions,
    clearAnsweres_,
    currentQuestionIndex,
    hideQuestion,
  } = props;
  // Setting up the local state for the component
  const [checked, setChecked] = useState("");
  const [checkedText, setCheckedText] = useState("");
  const [isFinishSurvey, setIsFinishSurvey] = useState(false);
  const [routeToIndex, setRouteToIndex] = useState("");
  // State to control the tooltip
  const [openTooltip, setOpenTooltip] = useState(null);

  // Handler function for handling user input
  const handleChange = (
    ratingId,
    value,
    text,
    routeToIndex,
    hideIndex,
    finishSurvey
  ) => {
    // Setting the checked rating and checkedText state
    setChecked(ratingId);
    setCheckedText(text);
    if (surveyType === "tablet" && currentQuestionIndex === 0) {
      clearAnsweres_();
      // Calculating the percentage based on the value
      const percentage = (Number(value) / Number(question?.maxScore)) * 100;
      // Filtering the questions based on the journey type
      if (percentage >= 70) {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "positive"
          );

        setAllQuestions([question, ...filteredQuestion]);
      } else {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "negative"
          );

        setAllQuestions([question, ...filteredQuestion]);
      }
    }
    // Function to hide a question
    if (!finishSurvey && hideIndex?.length) {
      hideQuestion(hideIndex);
    }
    setIsFinishSurvey(finishSurvey);
    if (!hideIndex?.includes(routeToIndex)) {
      setRouteToIndex(routeToIndex);
    }
    // Open the tooltip for the clicked emoji
    setOpenTooltip(ratingId);
  };

  const onSubmitAnswer = () => {
    if (question?.required && !checked) {
      toast.error("This Question is Required!");
      return;
    } else {
      addAnswer_({
        id: question._id,
        value: checked,
        type: "id",
      });
      if (!isFinishSurvey && routeToIndex) {
        //Function to navigate to the next question in the survey.
        headTonext(routeToIndex, question._id);
      }
      // Callback function invoked when an answer is submitted.
      onSend(checkedText);
    }
  };
  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  const CustomTooltip = withStyles({
    tooltip: {
      color: "white",
      backgroundColor: survey_theme?.primaryColor,
      fontSize: "0.8rem",
      top: "0.7rem",
    },
    arrow: {
      color: survey_theme?.primaryColor,
    },
  })(Tooltip);

  return (
    <>
      <div className="px-2 bg-white border-t border-gray-200">
        <div className="flex items-center justify-end space-x-3 h-18 px-2">
          {question.options.map((item, index) => {
            return (
              <>
                <Box
                  key={index}
                  className="ratingItem"
                  style={{
                    borderRadius: "50%",
                    border:
                      answeres[question._id]?.value === item._id ||
                      checked === item?._id
                        ? "2px solid gainsboro"
                        : "2px solid white",
                  }}
                >
                  <CustomTooltip
                    title={item?.optionText || ""}
                    placement="top"
                    open={openTooltip === item._id}
                    onClose={() => handleTooltipClose()}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                  >
                    <img
                      alt="emoji"
                      src={emojiImage[item.value - 1]}
                      onClick={() => {
                        handleChange(
                          item._id,
                          item.value,
                          item.value - 1,
                          item?.route_to_index,
                          item?.hide_questions,
                          item?.finish_survey === true
                        );
                      }}
                      style={{ padding: "2px" }}
                    />
                  </CustomTooltip>
                </Box>
              </>
            );
          })}

          <button
            onClick={onSubmitAnswer}
            type="submit"
            className="flex items-end justify-center flex-shrink-0 p-3 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
            style={{
              backgroundColor: survey_theme?.primaryColor,
            }}
          >
            <svg
              className="w-6 h-6 rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};
// This is the mapStateToProps function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// This is the mapDispatchToProps function that binds the dispatch function to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
  };
};
// Connects the EmojiType component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(EmojiType);

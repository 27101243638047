export const ADD_CONTACT_DETAILS = "ADD_CONTACT_DETAILS";
export const ADD_DIRECTOR_MESSAGE = "ADD_DIRECTOR_MESSAGE";
export const ADD_ANSWER = "ADD_ANSWER";
export const REMOVE_ANSWER = "REMOVE_ANSWER";
export const ADD_NOTE = "ADD_NOTE";
export const ADD_IMAGE = "ADD_IMAGE";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_TAGS = "SET_TAGS";

export const ADD_WRITEIN = "ADD_WRITEIN";
export const CLEAR_ANSWERES = "CLEAR_ANSWERES";
export const CLEAR_ANSWERESANDTAGS = "CLEAR_ANSWERESANDTAGS";
export const CLEAR_ANSWERES_AND_LOCATIONS = "CLEAR_ANSWERES_AND_LOCATIONS";
export const SET_SUREY_THEME = "SET_SUREY_THEME";
export const SET_SURVEY = "SET_SURVEY";
export const CLEAR_SURVEY = "CLEAR_SURVEY";
export const SET_GLOBAL_SURVEY = "SET_GLOBAL_SURVEY";
export const SET_IS_SURVEY_WITHOUT_LOGIN = "SET_IS_SURVEY_WITHOUT_LOGIN";
export const CLEAR_CONTACT_DETAILS = "CLEAR_CONTACT_DETAILS";
export const SET_GOOGLE_RATINGS_DETAILS = "SET_GOOGLE_RATINGS_DETAILS";
export const CLEAR_GOOGLE_RATINGS_DETAILS = "CLEAR_GOOGLE_RATINGS_DETAILS";

import React, { useEffect, useRef, useState } from "react";
import {
  addAnswer,
  addNote,
  addWriteIn,
  removeAnswer,
} from "../../../../redux/survey/actions";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import "rsuite/dist/styles/rsuite-default.css";
import "rsuite/dist/styles/rsuite-default.css";
import SignaturePad from "react-signature-canvas";
import "./signature.css";

function SignatureInput(props) {
  // Destructing props
  const { question, answeres, addAnswer_, isGreyOut, removeAnswer_ } = props;
  const signatureRef = useRef();
  // State variable for the dataURL of the signature image
  const [signature, setSignature] = useState(null);

  // Function to clear the canvas and reset the state variable
  const clearSignature = () => {
    signatureRef.current.clear();
    removeAnswer_({ id: question._id });
  };
  // Function to save the base64 encoded signature as a Data URL in the state variable
  const handleSignatureEnd = () => {
    setSignature(signatureRef.current.toDataURL());
    if (signatureRef.current.toDataURL()) {
      // Add answer to Redux store with the base64 encoded
      addAnswer_({
        id: question._id,
        value: signatureRef?.current?.toDataURL(),
        isFile: true,
      });
    }
  };
  let answer = answeres[question._id] ?? { value: "" }; // Extracts the answer related to this question or creates an empty
  useEffect(() => {
    const existingSignatureDataURL = answer?.value; // Get the saved signature from Redux store
    // Display the existing signature on the signature pad
    signatureRef.current.fromDataURL(existingSignatureDataURL, {
      width: 400,
      height: 150,
    });
  }, [answer?.value]); // Call whenever signature  changes

  return (
    <>
      <Box
        style={{ cursor: isGreyOut && "not-allowed" }}
        className="signature-box"
      >
        <SignaturePad
          ref={signatureRef}
          canvasProps={{
            width: 400,
            height: 150,
            className: "signature-canvas",
          }}
          onEnd={handleSignatureEnd}
        />
        <button
          className="btn btn-secondary close-btn"
          onClick={clearSignature}
        >
          Clear
        </button>
      </Box>
    </>
  );
}
// Function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Function that binds the dispatch to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addNote_: (data) => dispatch(addNote(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
    removeAnswer_: (data) => dispatch(removeAnswer(data)),
  };
};
// Connecting the Signature component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(SignatureInput);
